import React from "react";
import HeroSection from "../../components/hero-section";
import AboutSection from "../../components/about-section";
import Services from "../../components/services";
import Testimonial from "../../components/testimonial";
import Teams from "../../components/teams";
// import Courses from "../../components/courses";
import Contact from "../../components/contact";
import Parnerts from "../../components/partners";
import FAQSection from "../../components/faq-section";

const HomePage = () => {
  return (
    <>
      <header
        id="home"
        className="after:content-[' '] relative after:absolute after:bottom-0 after:right-0 after:top-0 after:-z-10 after:h-[full] after:w-full after:bg-slate-700 after:bg-hero-pattern after:bg-cover after:bg-no-repeat xl:pt-8"
      >
        <section className="pb-24 pt-36 sm:pb-24 sm:pt-40 md:pb-32 md:pt-48">
          <HeroSection />
        </section>
      </header>
      <section id="about" className="py-14 md:py-20">
        <AboutSection />
      </section>
      <section className="py-14 md:py-20">
        <Services />
      </section>
      <section id="testimonial">
        <Testimonial />
      </section>
      <section id="teams" className="py-14 md:py-20">
        <Teams />
      </section>
      <section id="teams" className="py-14 md:py-20">
        <FAQSection />
      </section>
      <section className="overflow-hidden py-8">
        <Parnerts />
      </section>
      <section id="contact" className="pb-40 pt-20">
        <Contact />
      </section>
    </>
  );
};

export default HomePage;
