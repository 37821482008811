import React from "react";
import { motion } from "framer-motion";

import Logo from "../assets/logo.svg";
import Calsa from "../assets/calsa.png";
import { Container } from "./common/Container";

const partnersLogos = [
  Logo,
  Calsa,
  Logo,
  Calsa,
  Logo,
  Calsa,
  Logo,
  Calsa,
  Logo,
  Calsa,
  Logo,
];

const Parnerts = () => {
  return (
    <Container>
      <h2 className="mb-10 text-center text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px]">
        Our Trusted Clients
      </h2>
      <div className="MyGradient flex">
        <motion.div
          initial={{ x: `${0}` }}
          animate={{ x: `${"-100%"}` }}
          transition={{ duration: 180, repeat: Infinity, ease: "linear" }}
          className="flex flex-shrink-0 py-5"
        >
          {[...partnersLogos,...partnersLogos,...partnersLogos]?.map((image, index) => {
            return (
              <img
                className="h-auto w-56 pr-20"
                src={image}
                alt="logo"
                key={index}
              />
            );
          })}
        </motion.div>
      </div>
    </Container>
  );
};

export default Parnerts;
